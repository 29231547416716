import React, { useState } from 'react';
import ItineraryItem from './itinerarioItem';
import { FaRing, FaCamera, FaMusic, FaUtensils, FaBirthdayCake, FaUserFriends  } from 'react-icons/fa';
function Itinerario() {
    const itinerary = [
        { time: '13:00 h', title: 'Recepción de invitados', icon: <FaUserFriends  /> },
        { time: '14:00 h', title: 'Ceremonia religiosa', icon: <FaRing /> },
        { time: '15:30 h', title: 'Sesión de fotos', icon: <FaCamera /> },
        { time: '16:00 h', title: 'Comida', icon: <FaUtensils  /> },
        { time: '18:00 h', title: 'Vals, Brindis', icon: <FaMusic /> },    
        { time: '19:00 h', title: 'Pastel', icon: <FaBirthdayCake  /> },
    ];
    return (
        <div className="container" >
            <div className='row'>
                <div className='col-12 col-md-8 col-lg-6 mx-auto'>
                    <div>
                        <strong className='custom-font-text'>ITINERARIO</strong><br/>
                    </div>
                    <div className="itinerary">
                        {itinerary.map((item, index) => (
                            <ItineraryItem
                                key={index}
                                time={item.time}
                                title={item.title}
                                icon={item.icon}
                            />
                        ))}
                    </div>
                    <div class="separator">
                        <div class="line"></div>
                        <i class="fa-sharp fa-solid fa-heart icon"></i>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Itinerario;